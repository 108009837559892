/* eslint-disable */
<template>
    <div class="pt-6">
        <v-container>
           <v-row>
            <v-col v-for="princing ,i of pricings" :key="i" cols="12" md="3">
                <PrincingCard :princing="princing" />
            </v-col>
            <v-col  cols="12" md="3">
                <ContactSalePricingCard :princing="contactSalePricing" />
            </v-col>

           </v-row>
        </v-container>
    </div>
</template>

<script>

import { methods, rules } from "@/utils/constant.js";
import { mapGetters, mapActions } from "vuex";
import PrincingCard from "../../components/user/PrincingCard.vue";
import ContactSalePricingCard from "../../components/user/ContactSalePricingCard.vue";
// import EditorX from "../../components/universal/EditorX.vue";
// import {
// } from "@/api/user.js";

export default {
    props:{
    
    },
    components: {
    PrincingCard,
    ContactSalePricingCard
},
    data() {
        return {
            rules: rules,

            contactSalePricing:{
                name:"OrangCode Enterprise",
                description:"OrangCode Enterprise allows you to have customized architectures and functionalities to be 10x faster. , please contact us .",
                description2:"This offer is only available for large enterprises",
                link:`mailto:${process.env.SALES_EMAIL || 'support@orangcode.com'}?subject=Enterprise%20Pricing%20Inquiry`,
                unit:"$",
                amount:0,
                period:"per month",
                comment:"No credit card required",
                featureDetailTitle:"Free features:",
                features:[],
                cardClass:"purple white--text",
                btnText:"Contact Sales",
                order:1,
            },
            princings:[{
                name:"Free",
                description:"Use Orang code for personal projects",
                unit:"$",
                amount:0,
                period:"per month",
                comment:"No credit card required",
                featureDetailTitle:"Free features:",
                features:[{
                    name:"5Gib storage"
                }],
                cardClass:"white",
                btnText:"Get started",
                order:1,
            }, 
            {
                name:"Standard",
                description:"Use Orang code for personal projects",
                unit:"$",
                amount:9.99,
                period:"per month",
                comment:"Billed annually at $99.99 USD",
                featureDetailTitle:"Standard features:",
                features:[{
                    name:"5Gib storage"
                }],
                cardClass:"orange white--text",
                btnText:"Buy Orangcode Standard",


            },
            {
                name:"Advanced",
                description:"Use Orang code for personal projects",
                unit:"$",
                amount:24.99,
                period:"per month",
                featureDetailTitle:"Advanced features:",
                comment:"Billed annually at $269.99 USD",
                features:[{
                    name:"5Gib storage"
                }],
                cardClass:"blue white--text",
                btnText:"Buy Orangcode Advanced",


            }
        ]
        };
    },
    computed: {
        ...mapGetters([
            "pricings",
         
        ]),


    },
    methods: {
        ...mapActions([
            "getPricings",
          
        ]),
        async initialize() {
            try {
                this.loading = true;
                await this.getPricings();
            } catch (error) {
                console.log("initialize home", error);
                methods.dispatchError(this.$store);
            } finally {
                this.loading = false;
        
            }
        },
        onSuccess() {
            this.initialize();
            this.$emit("refresh");
        },
    },

    mounted() {
        this.initialize();
    },
};
</script>

<style></style>