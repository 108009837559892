/* eslint-disable */
<template>
    <div class="">
        <div class="princing-card  container  " :class="princing.cardClass">
            <div>
                <div class=" ">
                    <div>
                        <h2 class="card-title">{{ princing.name }}</h2>
                        <p class="mb-1 card-description">{{ princing.description }}</p>

                    </div>
                    <div class="d-flex mt-3">

                        <div class="princing mr-3 ">
                            <span class="unit">{{ princing.unit }}</span>
                            <span class="price">{{ princing.amount }}</span>
                        </div>
                        <div class="princing-description d-flex align-center  ">
                            <div>
                                <p class="mb-0 ">{{ princing.period }}</p>
                                <p class="mb-0">{{princing.comment}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="mt-4 mb-3" v-if="detail">
                        <button class="button" @click="goTo(princing.link)">
                            {{ princing.btnText }}
                            <svg fill="currentColor" viewBox="0 0 24 24" class="universeicon">
                                <path clip-rule="evenodd"
                                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                                    fill-rule="evenodd"></path>
                            </svg>
                        </button>
                    </div>
                </div>


            </div>
        </div>
        <div class="princing-detail container" v-if="detail">
            <p class="princing-detail-title mt-2">{{ princing.featureDetailTitle }}</p>
            <div>
                <ul class="list ">
                    <li class="list-item" v-for="feature,i of princing.features" :key="i" ><span class="check">✓</span> {{ feature.name }} </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{

        princing:{
            required:true,
            type:Object
        },
        detail:{
            default:true,
            type:Boolean
        },
        
      
    },
    methods: {
    goTo(link) {
      this.$router.push(link);
    },
  },
}
</script>
<style>


.princing-card {
    border-radius: 8px;
}

.princing-card .card-title {
    font-size: 1.6rem;
    font-weight: 900;

}

.princing-card .card-description {
    font-size: 1.3rem;
    font-weight: 500;

}

.princing {
    position: relative
}

.princing .unit {
    position: absolute;
    top: 20%;
    left: 0;

}

.princing .price {
    font-size: 4.3rem;
    font-weight: 900;
    margin-left: .5rem;
}

.princing-description p {
    font-size: 1rem;
    font-weight: 400;
}

.princing-detail-title {
    font-size: .9rem;
    font-weight: 700;
}


.princing-detail .list {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  font-size: 0.75rem;
  color: rgb(30, 30, 30);
  font-weight: 500;
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.princing-detail .list .list-item .check {
  margin-right: 0.25rem;
  font-size: 1rem;
  color: rgb(10, 10, 10);
  font-weight: 900;
}
.button {
    position: relative;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    padding-block: 0.5rem;
    padding-inline: 1.25rem;
    background-color: rgb(0, 0, 0);
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #ffff;
    gap: 10px;
    font-weight: bold;
    border: 3px solid #ffffff4d;
    outline: none;
    overflow: hidden;
    font-size: 15px;
}

.universeicon {
    width: 24px;
    height: 24px;
    transition: all 0.3s ease-in-out;
}

.button:hover {
    transform: scale(1.05);
    border-color: #fff9;
}

.button:hover .universeicon {
    transform: translate(4px);
}

.button:hover::before {
    animation: shine 1.5s ease-out infinite;
}

.button::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(120deg,
            rgba(255, 255, 255, 0) 30%,
            rgba(255, 255, 255, 0.8),
            rgba(255, 255, 255, 0) 70%);
    top: 0;
    left: -100px;
    opacity: 0.6;
}

@keyframes shine {
    0% {
        left: -100px;
    }

    60% {
        left: 100%;
    }

    to {
        left: 100%;
    }
}
</style>